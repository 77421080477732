import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import FeaturedProperties from "../components/properties/FeaturedProperties"
import FilterProperties from "../components/properties/FilterProperties"
import ContactForm from "../components/forms/contact"

const Contacto = props => {
  const [pathname, setPathname] = useState("")
  useEffect(() => {
    setPathname(window.location.href)
  }, [])
  return (
    <Layout>
      <SEO title="Contáctenos" pathname={pathname} />
      <BannerTop title="Agentes Inmobiliarios" />
      <div className="container contents lisitng-grid-layout">
        <div className="row">
          <div className="span9 main-wrap">
            <div className="main">
              <div className="inner-wrapper">
                <p style={{ textAlign: "center" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d832.464817232209!2d-70.622776,17!3d-33.4300459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf63a93d5565%3A0x67d5df9ea316282f!2sAvenida%20Providencia%201208%2C%20oficina%20303%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sar!4v1609372967802!5m2!1ses-419!2sar"
                    width="90%"
                    height="300"
                    frameborder="0"
                    allowfullscreen="allowfullscreen"
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </p>
                <hr />

                <section className="contact-details clearfix">
                  <h3>Agente Inmobiliario</h3>
                  <address>
                  Casa Matriz: Avenida Providencia 1208, oficina 303,
                    <br />
                    Providencia, <br />
                    Región Metropolitana.
                  </address>
                  <ul className="contacts-list">
                    <li className="phone">
                      {" "}
                      Teléfonos: 2 2246 0994 (operaciones) – 2 2243 3478  
                      (administración)
                    </li>
                    <li className="email">
                      {" "}
                      Email Operaciones: operaciones@agenteinmobiliario.cl
                    </li>
                    <li className="email">
                      {" "}
                      Email Administración: administracion@agenteinmobiliario.cl
                    </li>
                    <li className="email">
                      {" "}
                      Email Ventas: propiedades@agenteinmobiliario.cl
                    </li>
                  </ul>
                </section>
                <section id="contact-form">
                  <h3 className="form-heading">
                    Complete el formulario y nos contactaremos a la brevedad
                  </h3>
                  <ContactForm location={props.location} />
                </section>
              </div>
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
              <FeaturedProperties quantity={2} />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contacto
